import { useState, useEffect } from 'react';
import React from 'react';
import { Button, Menu, Modal, message } from 'antd';
import './NotificationModel.css'
import { LuSettings } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import NotificationModelMenu from './NotificationModelMenu';
import NotificationModelReview from './NotificationModelReview';
import Instance from '../../AxiosConfig';

const NotificationModel = ({ isModalVisible, setIsModalVisible, setNotificationCount }) => {
    const items = [
        {
            label: "Overview",
            key: "overview",
        },
        {
            label: "Review",
            key: "review",
        },

    ];
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [current, setCurrent] = useState("overview");

    const onClick = (e) => {
        console.log("click ", e);
        setCurrent(e.key);
    };
    const handleSettingsClick = () => {
        navigate('/settings');
    };
    const fetchNotifications = async () => {
        try {
            const response = await Instance.get("/api/v1/notification/", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                }
            });
            if (response.status === 200) {
                console.log('Fetched notification data:', response.data);
                const orders = response.data.orders || [];
                const ratingReviews = response.data.ratingReviews || [];
                setNotifications(orders);
                setReviews(ratingReviews);
                const totalCount = orders.length + ratingReviews.length;
                setNotificationCount(totalCount);
            }
        } catch (error) {
            console.error("Error fetching faqs data:", error);
            message.error(
                error?.response?.data?.message || "An error occurred"
            );
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            fetchNotifications();
        }
    }, [isModalVisible]);

    useEffect(() => {
        fetchNotifications();

        const intervalId = setInterval(() => {
            fetchNotifications();
        }, 20000);

        return () => clearInterval(intervalId);
    }, []);

    const handleMarkAsRead = async () => {

        try {
            const response = await Instance.put(`/api/v1/notification/markAsRead`, {}, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("notifications cleared successfully:", response.data);
            setIsModalVisible(false);
            setNotificationCount(0);
        } catch (error) {
            console.error("Error clearing notifications ", error);
            message.error(
                error?.response?.data?.message || "An error occurred"
            );
        }
    };
    return (
        <Modal
            // title={<p></p>}
            className='notification-model-div'
            open={isModalVisible}
            // onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={[null]}
        >
            <div>
                <div className='container'>
                    {/* <div className='notification-model-header mt-4 mb-4'>
                        <div className='notification-model-span'>
                            <p><span >Turn on push notifications</span> to know when your product has been viewed and interacted with.</p>
                        </div>
                        <div className='notification-model-buttons-div'>
                            <button className='notification-model-button-left'>Allow push notifications</button>
                            <button className='notification-model-button-right'>Dismiss</button>
                        </div>
                    </div> */}
                    <div className='d-flex justify-content-between mt-3 notification-model-header-div'>
                        <h4 className='notification-model-h4'>Notifications</h4>
                        <button
                            className='mark-as-read-button'
                            onClick={handleMarkAsRead}
                        >
                            Mark As Read
                        </button>
                    </div>
                </div>
                <div>
                    <Menu
                        style={{ marginBottom: "30px" }}
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                        items={items}
                        className="manage-order-menu"
                    />
                    {current === "overview" && <NotificationModelMenu notifications={notifications}
                        setIsModalVisible={setIsModalVisible} />}
                    {current === "review" && <NotificationModelReview reviews={reviews} />}

                </div>
            </div>
        </Modal>
    )
}

export default NotificationModel
