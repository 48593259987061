import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Instance from '../../AxiosConfig';
import { message, Modal, Pagination, Rate, Spin, Switch } from 'antd';
import { RiDeleteBinLine } from 'react-icons/ri';
import Slider from 'react-slick/lib/slider';
import { FaPlus } from 'react-icons/fa';
import AddCoupon from '../Coupon/AddCoupon';
import { GrFormPreviousLink } from "react-icons/gr";
import { EditProductss } from './EditProductss';
import default_image from "../../Assets/images/defailt-no-image.png";
import { PRODUCT_BASE_URL } from '../../GlobalConstant';


const ProductCard = ({ category, images, title, weight, price, oldPrice, brand, rating, reviews, handleOpenEditProductModal, handleOpenAddCouponModal, productId, handleDeleteProduct, disabled, discountPercentage }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const { confirm } = Modal;

    const [productEnabled, setProductEnabled] = useState(disabled);

    const showDeleteConfirm = (productId) => {
        confirm({
            title: 'Are you sure you want to delete this product?',
            content: 'Once deleted, the product cannot be recovered.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteProduct(productId);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleProductToggle = async (productId, newStatus) => {
        try {
            const response = await Instance.patch(
                `api/v1/products/disabled-enabled/${productId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("API Response:", response);
            message.success(response.data.message);
            // Update local state after successful toggle
            setProductEnabled(newStatus);
        } catch (error) {
            message.error('Failed to update product status.');
            console.error('Error updating product status:', error);
        }
    };


    return (
        <div className="product-category-card-viewAll  ">
            <div className="d-flex justify-content-between">
                <p>{productId}</p>
                <div className="d-flex gap-2 align-items-center">
                    {/* Add Switch to control product enabled/disabled */}
                    <Switch
                        className="switch-input"
                        checked={productEnabled}  // Switch state controlled by productEnabled
                        onChange={(checked) => handleProductToggle(productId, checked)}
                        style={{ transform: "scale(0.8)" }}
                    />
                    <RiDeleteBinLine
                        className="delete_icon"
                        style={{ fontSize: '28px', cursor: 'pointer' }}
                        onClick={() => showDeleteConfirm(productId)}
                    />
                </div>
            </div>
            <div className="slider-container">
                <Slider {...sliderSettings} className="viewAll-slider">
                    {images.length > 0 ? (
                        images.map((imgSrc, index) => (
                            <img key={index} src={imgSrc && imgSrc.trim() !== "" ? `${PRODUCT_BASE_URL}${imgSrc}` : default_image} className="product-category-img-viewAll" alt={title} style={{ width: '0px' }} />
                        ))
                    ) : (
                        <img src="https://via.placeholder.com/150" className="product-category-img-viewAll" alt={title} /> // Use placeholder image URL directly
                    )}
                </Slider>
            </div>
            <div className="d-flex justify-content-between mt-4" >
                <h6 style={{ fontSize: '16px' }}>{title}</h6>
                <p>({weight})</p>
            </div>
            <div></div>
            <div className="d-flex gap-2">

                <div className="d-flex gap-2" >
                    <p>₹ {price} /</p>
                    <p style={{ textDecoration: "line-through" }}>₹ {oldPrice}</p>
                    <p className="productCategories-discount">{discountPercentage}% off</p>
                </div>

            </div>
            <div className="d-flex gap-2">
                <Rate disabled allowHalf value={rating} style={{ display: "flex", justifyContent: "start" }} />
                <p >({reviews})</p>
            </div>
            <div className="d-flex productCategories-buttons-div align-items-center">
                <button className="edit-product-category-viewAll" onClick={handleOpenEditProductModal}>Edit Product</button>
                <button className="add_new_coupon_button-viewAll" onClick={handleOpenAddCouponModal}><FaPlus /> &nbsp; New Coupon</button>
            </div>
        </div>
    );
};

const ViewAllProducts = () => {
    const location = useLocation();
    const { category } = location.state || { category: 'default-category' };
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [isCouponModalVisible, setIsCouponModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(9);
    const [isEditModalClosed, setIsEditModalClosed] = useState(false);
    const navigate = useNavigate();


    const handlePrevious = () => {
        navigate("/ProductCategories")
    }

    const handleOpenAddCouponModal = async (productId) => {
        console.log("hduejdu:", productId);
        setSelectedProductId(productId);
        setIsCouponModalVisible(true); // Open coupon modal
    };

    const handleOpenEditProductModal = async (productId) => {
        console.log("Product ID:", productId);
        try {
            const response = await Instance.get(`/api/v1/products/${productId}`);
            setSelectedProduct(response.data);
            console.log("df:", response.data)
            setIsModalVisible(true);
        } catch (error) {
            console.error("Failed to fetch product details:", error);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            await Instance.delete(`/api/v1/products/${productId}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                }
            });
            setProducts((prevProducts) => {
                const updatedProducts = { ...prevProducts };
                Object.keys(updatedProducts).forEach((category) => {
                    updatedProducts[category] = updatedProducts[category].filter(product => product.productId !== productId);
                });
                return updatedProducts;
            });
            message.success("Product deleted successfully");
        } catch (error) {
            console.error("Failed to delete product:", error);
        }
    };
    const fetchProductsByCategory = async () => {
        setLoading(true);
        console.log("Fetching products for category:", category);
        try {
            const response = await Instance.get(`/api/v1/products/grouped-by-category-with-disabled`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                },
            });
            console.log("abc", response.data)
            const filteredProducts = response.data[category] || [];
            console.log("FilteredProducts:", filteredProducts)
            setProducts(filteredProducts);
        } catch (error) {
            console.error("Failed to fetch products:", error.response);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductsByCategory();
    }, [category]);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };


    if (loading) {
        return <div className="loading"><Spin size="large" /></div>; // Optionally, add a spinner or loading message
    }

    return (
        <div className='container'>
            <>
                <h4 className='mb-3' style={{ textAlign: 'left' }}>Overall Products</h4>
                {products.length > 0 ? (
                    <>
                        <div className="all-categories-grid-viewAll">
                            {currentProducts.map((product, index) => (
                                <div key={index} className="all-categories-card-viewAll">
                                    <ProductCard
                                        category={category}
                                        images={[product.imageUrl, product.imageUrl1, product.imageUrl2]}
                                        productId={product.productId}
                                        title={product.title}
                                        weight={product.weight || '50kg'}
                                        brand={product.brand}
                                        price={product.discountedPrice}
                                        oldPrice={product.price}
                                        rating={product.numRatings}
                                        disabled={product.disabled}
                                        reviews={product.totalReviews}
                                        discountPercentage={product.discountPercentage}
                                        handleOpenEditProductModal={() => handleOpenEditProductModal(product.productId)}
                                        handleOpenAddCouponModal={() => handleOpenAddCouponModal(product.productId)}
                                        handleDeleteProduct={handleDeleteProduct}
                                    />
                                </div>
                            ))}

                            <AddCoupon isModalVisible={isCouponModalVisible} setIsModalVisible={setIsCouponModalVisible} productId={selectedProductId} />
                            <EditProductss
                                isModalVisible={isModalVisible}
                                setIsModalVisible={(visible) => {
                                    setIsModalVisible(visible);
                                    if (!visible) {
                                        setIsEditModalClosed(!isEditModalClosed);
                                    }
                                }}
                                selectedProduct={selectedProduct}
                            />
                        </div>
                        {/* Add Pagination */}
                        <Pagination
                            current={currentPage}
                            total={products.length}
                            pageSize={productsPerPage}
                            onChange={handleChangePage}
                            style={{ textAlign: 'center', marginTop: '20px' }}
                        />
                        <div>
                            <button className='ViewAll-products-previous' onClick={handlePrevious}><GrFormPreviousLink />
                                Previous</button>
                        </div>
                    </>
                ) : (
                    <div>No {category} products available</div>
                )}
            </>
        </div>
    )
}

export default ViewAllProducts