import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { PlusOutlined, MoreOutlined, MinusOutlined } from '@ant-design/icons';
import { Collapse, Button, Menu, Dropdown, message, Spin, Popconfirm } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { FiEdit3 } from "react-icons/fi";
import Instance from '../../AxiosConfig';
import './Faqs.css';
import EditFaqs from "./EditFaqs";
import { addfaqs, removefaq } from '../../states/reducers/FAQslice';
import { useDispatch, useSelector } from 'react-redux';
import { RiDeleteBinLine } from 'react-icons/ri';
const { Panel } = Collapse;

function FaqsList() {
  const [expandIconPosition, setExpandIconPosition] = useState('start');
  const [swipedItems, setSwipedItems] = useState({});
  const [deletedItems, setDeletedItems] = useState([]);
  // const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState([]);

  const onChange = (key) => {
    setActiveKey(key);
  };
  useEffect(() => {
    console.log("Updated activeKey:", activeKey);
  }, [activeKey]);

  const handleOpenAddPostModal = (faqId) => {
    setSelectedFaq(faqId);
    console.log("dfgh", faqId)
    setIsModalVisible(true);
  };

  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  // const onChange = (key) => {
  //   console.log(key);
  // };

  const dispatch = useDispatch();
  const FaqData = useSelector(state => state.faq.FAQData);
  console.log("dispatch:", FaqData)
  const fetchFaqsData = async () => {
    setLoading(true);
    try {
      const response = await Instance.get("/api/v1/faqs", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        dispatch(addfaqs(response.data));
        console.log('Fetched FAQ data:', response.data);
        // setFaqData(response.data);
      }
    } catch (error) {
      console.error("Error fetching faqs data:", error);
      message.error(
        error?.response?.data?.message || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqsData(dispatch);
  }, [dispatch]);

  console.log('FAQ Data:', FaqData);

  const deleteFaq = async (faqId) => {
    try {
      console.log("Deleting FAQ with ID:", faqId);
      const response = await Instance.delete(`/api/v1/faqs/${faqId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        message.success("FAQ deleted successfully");
        dispatch(removefaq(faqId));
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      message.error(
        error?.response?.data?.message || "An error occurred"
      );
    }
  };

  const getPrintMenu = (faqId) => (
    <Menu>
      <Menu.Item key="print" onClick={() => handleOpenAddPostModal(faqId)}>
        <span className='add_faqs_edit_text'>Edit</span>
        <FiEdit3 className='add_faqs_edit_icon' />
      </Menu.Item>
      <Menu.Item key="delete">
        <Popconfirm
          title="Are you sure you want to delete this FAQ?"
          onConfirm={() => deleteFaq(faqId.faqId)}
          onCancel={() => message.info('FAQ deletion aborted')}
          okText="Yes"
          cancelText="No"
        >
          <span className='add_faqs_delete_text'>Delete</span>
          <RiDeleteBinLine className="add_faqs_delete_icon" />
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const genExtra = (faq) => (

    <div className='d-flex'>
      {activeKey.includes(faq.faqId.toString()) ? (
        <MinusOutlined className="plus_icon" />
      ) : (
        <PlusOutlined className="plus_icon" />
      )}
      <Dropdown overlay={getPrintMenu(faq)} trigger={['click']}>
        <MoreOutlined
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            visibility: swipedItems[faq.faqId] ? 'hidden' : 'visible',
            position: 'relative',
            left: '40px',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
      </Dropdown>
    </div>
  );

  return (
    <div className='container-fluid'>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Collapse
            activeKey={activeKey}
            onChange={onChange}
            expandIconPosition="right"
            className="admin-faq-collapse mb-4"
          >
            {FaqData.map((item) => (
              <Panel
                header={
                  <div onClick={(e) => e.stopPropagation()}>
                    {item.title}
                  </div>
                }
                key={item.faqId}
                extra={genExtra(item)}
                className={`ant-collapse-panel ${swipedItems[item.faqId] ? 'swiped' : ''}`}
                data-key={item.faqId}
              >
                <div className='ant_collapse_content_div'>
                  <p className='ant_collapse_content_p1'>{item.title}</p>
                  <p className='ant_collapse_content_p2'>{item.description}</p>
                </div>
              </Panel>
            ))}
          </Collapse>
          <EditFaqs isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} faq={selectedFaq} />
        </>
      )}
    </div>
  );
}

export default FaqsList;
