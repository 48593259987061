import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Instance from './AxiosConfig';

const Protect = ({ Component }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const token = sessionStorage.getItem('adminAuthToken');
    const roleName = JSON.parse(sessionStorage.getItem('userData'));

    const rolePathMapping = {
        "Dashboard Access": "/dashboard",
        "Manage Users": "/Users",
        "Manage Users Details": "/customer-details",
        "Manage Orders": "/manage-orders",
        "Access to Admin Roles": "/admin-roles",
        "Manage Coupons": "/coupon",
        "Product Categories Access": "/ProductCategories",
        "Access to View All Products": "/view-all-products",
        "Transaction Management": "/transaction",
        "Add Products": "/add-product",
        "Manage Brands": "/brands",
        "Account Management": "/account",
        "Product List Access": "/products-list",
        "Product Detail Access": "/product-details/:productListId",
        "Access to Manage Admins": "/manage-admin",
        "Access to Configure Settings": "/settings",
        "Access to Review": "/review",
        "Access to FAQ": "/faqs",
        "Access to Contact": "/contact-us",
        "Access to Feedback": "/feedback",
        "Access to Store Information": "/store-information",
        "Store Details Management": "/store-details/:storeInfoId"
    };

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        const fetchRoleAccess = async () => {
            try {
                const response = await Instance.get(`/api/v1/roleAccess/${roleName}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const userRoleAccess = response.data || [];
                const currentPath = location.pathname;
                if (currentPath === '/logout') {
                    setHasAccess(true);
                    setLoading(false);
                    return;
                }
                const canAccess = userRoleAccess.some((role) => {
                    const expectedPath = rolePathMapping[role.name];
                    const isDynamicMatch = expectedPath && expectedPath.includes(':') && currentPath.startsWith(expectedPath.split('/:')[0]);

                    return role.roleAccess && (currentPath === expectedPath || isDynamicMatch);
                });
                setHasAccess(canAccess);
                if (!canAccess) {
                    navigate('/not-accessible');
                }
            } catch (error) {
                console.error('Failed to fetch role access:', error);
                navigate('/not-accessible');
            } finally {
                setLoading(false);
            }
        };

        fetchRoleAccess();
    }, [token, roleName, location, navigate]);



    return hasAccess ? <Component /> : null;
};

export default Protect;
