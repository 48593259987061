import { createSlice } from '@reduxjs/toolkit';

export const RoleSlice = createSlice({
  name: 'roles',
  initialState: {
    roleData: [],
  },
  reducers: {
    setRoles(state, action) {
      state.roleData = action.payload;
    },
    addRole(state, action) {
      state.roleData.push(action.payload);
    },
    deleteRole(state, action) {
      state.roleData = state.roleData.filter(role => role.roleId !== action.payload);
    },
  },
});

export const { setRoles, addRole, deleteRole } = RoleSlice.actions;
export default RoleSlice.reducer;
