import React, { useState, useEffect } from "react";
import { Table, Pagination, Select, Modal } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { RiDeleteBinLine } from "react-icons/ri";
import { message } from "antd";

const { Option } = Select;

const bulkActions = [
  { value: "In stock", label: "In Stock" },
  { value: "Low stock", label: "Low Stocks" },
  { value: "Out of stock", label: "Out of Stocks" },
];

const StoreInformations = ({ searchText, selectedDate, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const navigate = useNavigate();
  const { confirm } = Modal;

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  useEffect(() => {
    handleFilterAndPagination();
  }, [
    pagination.current,
    pagination.pageSize,
    data,
  ]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/store-info/all";
      const response = await Instance.get(url);
      console.log("API Response:", response.data);

      if (response.status === 200) {
        const stores = response.data;
        setData(stores);

        console.log("Fetched Stores:", stores);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.response);
    }
    setLoading(false);
  };

  const handleFilterAndPagination = () => {
    let filtered = data;

    setFilteredData(filtered);

    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  const handleRowClick = (storeInfoId) => {
    navigate(`/store-details/${storeInfoId}`);
  };

  const handleDeleteClick = (e, storeInfoId) => {
    e.preventDefault();
    e.stopPropagation();
    showDeleteConfirm(storeInfoId);
  };

  const showDeleteConfirm = (storeInfoId) => {
    confirm({
      title: 'Are you sure you want to delete this store?',
      content: 'Once deleted, the store information cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(storeInfoId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDelete = async (storeInfoId) => {
    try {
      const response = await Instance.delete(`api/v1/store-info/delete/${storeInfoId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        }
      });

      if (response.status === 200 || response.status === 201) {
        message.success("Store information deleted successfully!");
        fetchStoreInfo();
      } else {
        message.error("Failed to delete the store information.");
      }
    } catch (error) {
      message.error("An error occurred while deleting the store.");
      console.error("Delete error:", error);
    }
  };

  const columns = [
    {
      title: "Store Id",
      dataIndex: "storeInfoId",
      width: 500,

    },
    {
      title: "Store Manager",
      dataIndex: "storeManager",
      width: 500,

    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      width: 500,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Ph.No",
      dataIndex: "phoneNumber",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Action",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="delete_edit_icons">

          <RiDeleteBinLine
            className="delete_icon"
            onClick={(e) => handleDeleteClick(e, record.storeInfoId)}
          />{" "}
        </div>
      ),
    },

  ];

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const paginatedData = filteredData.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  );

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start" }} >
          <span>Store Information</span>
        </div>
      </div>
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record.storeInfoId),
          })}
          rowClassName="StoreInformation-table-row"
        />
        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={(value) =>
                handlePageSizeChange(pagination.current, value)
              }
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            showSizeChanger={false}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreInformations;
