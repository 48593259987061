import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, Upload, message, Spin } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Instance from "../../AxiosConfig";
import { DatePicker } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import dayjs from 'dayjs';
import defaultImage from "../../Assets/images/categories.png"
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from 'react';


const EditProducts = ({ isModalVisible, setIsModalVisible, }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [file, setFile] = useState(null);
  const [mainImg, setMainImg] = useState(null);
  const { productListId } = useParams();
  const [product, setProduct] = useState();
  const [formData, setFormData] = useState({
    productName: "",
    category: "",
    brandName: "",
    productSpecification: "",
    buyingPrice: "",
    quantity: "",
    totalKg: "",
    receivedDate: null,
    supplierName: "",
    supplierPhoneNo: "",
    // imageUrl: null,
  });

  const getProductCategory = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Categories fetched:", response.data);
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
    setLoading(false);
  };

  const getBrandsByCategory = async (categoryName) => {
    if (!categoryName) {
      console.error("Category name is undefined");
      return;
    }
    setLoading(true);
    try {
      const url = `/api/v1/brand/${categoryName}`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Brands fetched:", response.data);
        setBrands(response.data);
      } else {
        setBrands([]);
      }
    } catch (error) {
      console.error("Failed to fetch brands:", error);
      setBrands([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductCategory();
  }, []);

  const fetchProductRef = useRef(null);


  useEffect(() => {
    if (selectedCategory) {
      console.log("Selected category:", selectedCategory.value);
      getBrandsByCategory(selectedCategory.value);
    }
  }, [selectedCategory]);




  const handleCategoryChange = (value, option) => {
    console.log("Category selected:", option);
    setSelectedCategory(option);
    setFormData({
      ...formData,
      category: option.value,
      brandName: "",
    });
  };



  const handleBrandChange = (value, option) => {
    setFormData({ ...formData, brandName: option.value });
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await Instance.get(`/api/v1/admin/product/${productListId}`);
        if (response.status === 200) {
          const fetchedProduct = response.data;
          console.log("fetchedProduct", fetchedProduct)
          setProduct(fetchedProduct);
          setFormData({
            productName: fetchedProduct.productName || "",
            category: fetchedProduct.category || "",
            brandName: fetchedProduct.brandName || "",
            productSpecification: fetchedProduct.productSpecification || "",
            buyingPrice: fetchedProduct.buyingPrice || "",
            quantity: fetchedProduct.quantity || "",
            totalKg: fetchedProduct.totalKg || "",
            receivedDate: fetchedProduct.receivedDate ? dayjs(fetchedProduct.receivedDate).format('YYYY-MM-DD HH:mm:ss') : null,
            supplierName: fetchedProduct.supplierName || "",
            supplierPhoneNo: fetchedProduct.supplierPhoneNo || "",
          });
          setSelectedCategory(
            categories.find(category => category.categoryTitle === fetchedProduct.category) || null
          );
          setSelectedBrand(fetchedProduct.brandName || null);

          if (fetchedProduct.imageData) {
            // Decode the base64 string
            const byteCharacters = atob(fetchedProduct.imageData);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob object from the byte array
            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            // Create an object URL for the Blob
            const imageUrl = URL.createObjectURL(blob);

            // Set the image URL and keep the original image name
            setMainImg({
              url: imageUrl,
              name: fetchedProduct.imageName || '', // Keep the original image name or use a default
            });
          }

        }
      } catch (error) {
        console.error("Failed to fetch product:", error);
      }finally{
        setLoading(false);
      }
      
    };
    fetchProductRef.current = fetchProduct;

    fetchProduct();
  }, [productListId, categories]);

  const brandOptions = brands.map((brand) => ({
    value: brand.brandName,
    label: brand.brandName,
  }));

  const categoryOptions = categories.map((category) => ({
    value: category.categoryTitle,
    label: category.categoryTitle,
  }));

  const [errors, setErrors] = useState({});


  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const originalFileName = file.name; // Store the original file name

      const reader = new FileReader();

      reader.onloadend = () => {
        // Convert the file data to a Uint8Array
        const byteArray = new Uint8Array(reader.result);
        // Create a Blob from the byte array
        const blob = new Blob([byteArray], { type: file.type });
        // Create an object URL from the Blob
        const imageUrl = URL.createObjectURL(blob);

        // Set both the image URL and the original file name in the state
        setMainImg({ url: imageUrl, name: originalFileName });
      };

      // Read the file as an ArrayBuffer to create a Blob
      reader.readAsArrayBuffer(file);

      // Clear any existing image-related errors
      setErrors({
        ...errors,
        image: "",
      });
    }
  };



  const handleDeleteImage = () => {
    setMainImg(null);
    setFormData({
      ...formData,
      // imageUrl: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "productSpecification" && value.length > 100) {
      setErrors({
        ...errors,
        productSpecification: "Product Specification must be 100 characters or less",
      });
    } else if (name === "supplierPhoneNo" && (!/^\d*$/.test(value) || value.length > 10)) {
      setErrors({
        ...errors,
        supplierPhoneNo: "Supplier Phone No. must be 10 digits and contain only numbers",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleAddProduct = async () => {
    setLoading(true)
    const newErrors = {};
    if (!formData.productName) newErrors.productName = "Product Name is required";
    if (!formData.category) newErrors.category = "Category is required";
    // if (!formData.brandName) newErrors.brandName = "Brand name is required";
    if (!formData.productSpecification) newErrors.productSpecification = "Product Specification is required";
    if (!formData.buyingPrice) newErrors.buyingPrice = "Buying Price is required";
    if (!formData.quantity) newErrors.quantity = "Quantity is required";
    if (!formData.totalKg) newErrors.totalKg = "Total KG is required";
    if (!formData.receivedDate) newErrors.receivedDate = "Received Date is required";
    if (!formData.supplierName) newErrors.supplierName = "Supplier Name is required";
    // if (!formData.supplierPhoneNo || formData.supplierPhoneNo.length !== 10) newErrors.supplierPhoneNo = "Supplier Phone No. must be 10 digits";
    if (!mainImg) newErrors.image = "Product Image is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      message.error("Please fill in all required fields");
      setLoading(false);
      return;
    }
    const productListDto = {
      productName: formData.productName,
      category: formData.category,
      brandName: selectedBrand,
      productSpecification: formData.productSpecification,
      buyingPrice: formData.buyingPrice,
      quantity: formData.quantity,
      totalKg: formData.totalKg,
      receivedDate: formData.receivedDate,
      supplierName: formData.supplierName,
      supplierPhoneNo: formData.supplierPhoneNo,

    };

    const form = new FormData();
    form.append("productListDto", JSON.stringify(productListDto));

    if (mainImg) {
      const response = await fetch(mainImg.url);
      const blob = await response.blob();

      form.append("file", blob, mainImg.name || ''); // 'default.jpg' as a fallback
    }
    for (let pair of form.entries()) {
      console.log(pair[0], pair[1]);
      console.log("Selected Brand:", selectedBrand);
      console.log("Product List Dto:", productListDto);
    }
    for (let pair of form.entries()) {
      console.log(pair[0], pair[1]);
    }

    try {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        "Content-Type": "multipart/form-data",
      };
      const response = await Instance.put(`api/v1/admin/product/update/${productListId}`, form, {
        headers,
      });
      if (response.status === 200 || response.status === 201) {
        message.success("Product updated successfully!");
        resetForm();
        setIsModalVisible(false);
        fetchProductRef.current();
      } else {
        message.error("Failed to edit product.");
        console.log(response.data)
      }
    } catch (error) {
      console.error("Error adding product:", error.response);
      message.error("Failed to edit product.");
    }finally{
      setLoading(false);
    }
    
  };

  const resetForm = () => {
    setFormData({
      productName: "",
      category: "",
      brandName: "",
      productSpecification: "",
      buyingPrice: "",
      quantity: "",
      totalKg: "",
      receivedDate: null,
      supplierName: "",
      supplierPhoneNo: "",
      // imageUrl: null,
    });
    setFile(null);
    setSelectedCategory(null);
    setSelectedBrand(null);
    setBrands([]);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFile(info.file.originFileObj);
      setLoading(false);
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      setFormData({
        ...formData,
        receivedDate: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        receivedDate: null,
      });
    }
  };



  return (
    <Modal
      title="Edit Product"
      visible={isModalVisible}
      onOk={handleAddProduct}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button
          className="discard_button"
          key="cancel"
          onClick={() => setIsModalVisible(false)}
        >
          Discard
        </Button>,
        <Button
          className="save_changes_button"
          key="submit"
          type="primary"
          onClick={handleAddProduct}
        >
          {loading ? (
            <Spin size="small" />
          ) : (
            "Update Product"
          )}
        </Button>,
      ]}
    >
      <div className="add-user-section">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <label className="add_product_label">Product Image</label>
            <input
              type="file"
              name="imageUrl"
              accept="image/*"
              onChange={handleMainImageChange}
              style={{ display: "none" }}
            />
            {!mainImg?.url && (
              <Button
                className="d-flex align-items-center btn btn-secondary button-image gap-2"
                style={{ padding: "50px", backgroundColor: "#fff", border: "1px dashed #000" }}
                onClick={() => document.getElementsByName("imageUrl")[0].click()}
              >
                <PlusOutlined />
                Upload
              </Button>
            )}
            {mainImg?.url && (
              <div className="mt-2 d-flex mb-2">
                <img src={mainImg.url} alt={mainImg.name || "Preview"} style={{ maxWidth: "100px" }} />

                <Button type="link" onClick={handleDeleteImage}>
                  <AiOutlineDelete style={{ color: "red", fontSize: "20px" }} />
                </Button>
              </div>
            )}
            {errors.image && <p style={{ color: "red" }}>{errors.image}</p>}
          </div>

          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Product Name</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Product Name"
                name="productName"
                className="form-control custom-placeholder"
                value={formData.productName}
                onChange={handleInputChange}
              />
              {errors.productName && <p style={{ color: "red" }}>{errors.productName}</p>}
            </div>
          </div>
          <div className="row mb-2 mt-2">
            <div className="col-lg-5 mt-2">
              <p>Category</p>
            </div>
            <div className="col-lg-7">
              <Select
                options={categoryOptions}
                placeholder="Select Product Category"
                value={formData.category}
                onChange={handleCategoryChange}
                style={{ width: "250px" }}
              />
              {errors.category && <p style={{ color: "red" }}>{errors.category}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Brand Name</p>
            </div>
            <div className="col-lg-7">
              <Select
                placeholder="Select Brand Name"
                options={selectedCategory ? brandOptions : []}
                disabled={!selectedCategory}
                value={selectedBrand}
                onChange={(selectedOption) => {
                  console.log("Selected Brand:", selectedOption);
                  setSelectedBrand(selectedOption);
                }}
                style={{ width: "250px" }}
              />
              {/* {errors.brandName && <p style={{ color: "red" }}>{errors.brandName}</p>} */}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Product Specification</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Product Specification"
                name="productSpecification"
                className="form-control custom-placeholder"
                value={formData.productSpecification}
                onChange={handleInputChange}
              />
              {errors.productSpecification && <p style={{ color: "red" }}>{errors.productSpecification}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Buying Price</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Buying Price"
                name="buyingPrice"
                className="form-control custom-placeholder"
                value={formData.buyingPrice}
                onChange={handleInputChange}
              />
              {errors.buyingPrice && <p style={{ color: "red" }}>{errors.buyingPrice}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Quantity</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Product Quantity"
                name="quantity"
                className="form-control custom-placeholder"
                value={formData.quantity}
                onChange={handleInputChange}
              />
              {errors.quantity && <p style={{ color: "red" }}>{errors.quantity}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Total KG</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Product Total KG"
                name="totalKg"
                className="form-control custom-placeholder"
                value={formData.totalKg}
                onChange={handleInputChange}
              />
              {errors.totalKg && <p style={{ color: "red" }}>{errors.totalKg}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Received Date</p>
            </div>
            <div className="col-lg-7">
              <DatePicker
                className="custom-date-picker p-2"
                placeholder="Select Date"
                value={formData.receivedDate ? dayjs(formData.receivedDate) : null}
                onChange={handleDateChange}
              />
              {errors.receivedDate && <p style={{ color: "red" }}>{errors.receivedDate}</p>}
            </div>
          </div>
          <h5>Supplier Details</h5>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Supplier Name</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Supplier Name"
                name="supplierName"
                className="form-control custom-placeholder"
                value={formData.supplierName}
                onChange={handleInputChange}
              />
              {errors.supplierName && <p style={{ color: "red" }}>{errors.supplierName}</p>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 mt-2">
              <p>Supplier Phone no.</p>
            </div>
            <div className="col-lg-7">
              <Input
                placeholder="Enter Supplier Phone no."
                name="supplierPhoneNo"
                className="form-control custom-placeholder"
                value={formData.supplierPhoneNo}
                onChange={handleInputChange}
              />
              {errors.supplierPhoneNo && <p style={{ color: "red" }}>{errors.supplierPhoneNo}</p>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProducts;
