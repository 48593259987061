import React, { useState, useEffect } from "react";
import { Modal, message, Rate } from "antd";
import profile from "../../Assets/images/singleuser.png";
import TextArea from "antd/es/input/TextArea";
import Instance from "../../AxiosConfig";

const ReviewReply = ({ isModalVisible, setIsModalVisible, ratingReviewId, rating }) => {
  const [loading, setLoading] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [reviewData, setReviewData] = useState({});
  const [userImage, setUserImage] = useState(profile);

  useEffect(() => {
    if (isModalVisible) {
      console.log('ratingReviewId:', ratingReviewId);
      console.log("posted rsting:", rating)
      fetchReviewData();
    }
  }, [isModalVisible]);

  const fetchReviewData = async () => {
    try {
      const response = await Instance.get("/api/v1/rating/review/all", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        },
      });

      if (response.status === 200) {
        const review = response.data.find(
          (review) => review.ratingReviewId === ratingReviewId
        );
        if (review) {
          setReviewData(review);
          setUserImage(review.userImage || profile);
        } else {
          message.error("Review not found.");
        }
      } else {
        message.error("Failed to fetch reviews. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred while fetching reviews. Please try again.");
    }
  };

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Instance.post(
        `/api/v1/replies/ratingReview/${ratingReviewId}`,
        {
          replyText: replyContent,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data)
        message.success("Reply sent successfully!");
        setIsModalVisible(false);
        setReplyContent("");
      } else {
        message.error("Failed to send reply. Please try again.");
        console.log(response)
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Reply"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div>
          <div className="d-flex justify-content-between mt-4">
            <div className="d-flex gap-2 align-items-center">
              <div className="profile-email-icon">
                <img src={userImage} alt=".." />
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                  {reviewData.userName}
                </p>
              </div>
            </div>
            <p>{reviewData.createdDate}</p>
          </div>
          <div className="d-flex gap-4 pt-3">
            <h6 className="reviews-how-to-apply">{reviewData.title}</h6>
            <Rate allowHalf value={rating} />
          </div>
          <p>{reviewData.review}</p>
          <TextArea
            placeholder="Reply here..."
            className="form-control custom-placeholder mt-4"
            style={{ backgroundColor: "#f6f6f6", resize: "none", height: "150px" }}
            value={replyContent}
            onChange={handleReplyChange}
          />
          <button
            className="login-btn mt-4"
            id="login-button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Sending..." : "Reply"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ReviewReply;
