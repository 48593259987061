import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Radio, message } from "antd";
import ReactQuill from "react-quill";
import Instance from "../../AxiosConfig";
import { editfaq } from "../../states/reducers/FAQslice";
import { useDispatch } from "react-redux";

const EditFaqs = ({ isModalVisible, setIsModalVisible, faq }) => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        status: "true",
    });
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        title: "",
        description: "",
        status: "",
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (faq) {
            setFormData({
                title: faq.title || "",
                description: faq.description || "",
                status: faq.status ? "true" : "false",
            });
            setValue(faq.description || '');
        }
    }, [faq]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
        setErrors(prev => ({
            ...prev,
            [name]: "",
        }));
    };

    const handleQuillChange = (content) => {
        setValue(content);
        const strippedContent = stripHtml(content);
        let descriptionError = "";
        if (!strippedContent) {
            descriptionError = "Description is required.";
        } else if (strippedContent.length > 1200) {
            descriptionError = "Description must be less than or equal to 1200 characters.";
        }
        setFormData(prev => ({
            ...prev,
            description: content,
        }));
        setErrors(prev => ({
            ...prev,
            description: descriptionError,
        }));
    };

    const stripHtml = (html) => {
        let div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const handleStatusChange = (e) => {
        setFormData(prev => ({
            ...prev,
            status: e.target.value,
        }));
        setErrors(prev => ({
            ...prev,
            status: "",
        }));
    };

    const handleSubmit = async () => {
        if (!faq) return;
        const faqId = faq.faqId;
        const strippedDescription = stripHtml(formData.description);
        let valid = true;

        const newErrors = {
            title: "",
            description: "",
            status: "",
        };

        if (!formData.title) {
            newErrors.title = "Title is required.";
            valid = false;
        }

        if (!strippedDescription) {
            newErrors.description = "Description is required.";
            valid = false;
        } else if (strippedDescription.length > 1200) {
            newErrors.description = "Description must be less than or equal to 1200 characters.";
            valid = false;
        }

        if (formData.status === undefined) {
            newErrors.status = "Status is required.";
            valid = false;
        }

        setErrors(newErrors);

        if (!valid) {
            return;
        }

        setLoading(true);
        try {
            const response = await Instance.put(`/api/v1/faqs/update/${faqId}`, {
                ...formData,
                description: strippedDescription,
                status: formData.status === "true",
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("FAQ updated successfully:", response.data);
            dispatch(editfaq({
                faqId: faq.faqId,
                ...formData,
                description: strippedDescription,
                status: formData.status === "true",
            }));
            setIsModalVisible(false);
            // setFormData({
            //     title: "",
            //     description: "",
            //     status: "true",
            // });
            // setValue('');
            message.success("FAQ updated successfully");
        } catch (error) {
            console.error("Error updating FAQ:", error);
            message.error(
                error?.response?.data?.message || "An error occurred"
            );
        } finally {
            setLoading(false);
        }
    };

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            ['link']
        ]
    };

    const formats = [
        'font',
        'bold', 'italic', 'underline',
        'align',
        'link'
    ];

    return (
        <Modal
            title="Edit FAQ"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Discard
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Save Changes
                </Button>,
            ]}
        >
            <div className="add-user-section">
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Add Title</p>
                        <Input
                            type="text"
                            name="title"
                            placeholder="What are the key features of UltraTech Cement?"
                            className="add_title_question"
                            value={formData.title}
                            onChange={handleInputChange}
                        />
                        {errors.title && <div className="error-message">{errors.title}</div>}
                    </div>
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Description</p>
                        <ReactQuill
                            className="mb-3"
                            value={value}
                            onChange={handleQuillChange}
                            placeholder="Your text goes here"
                            modules={modules}
                            formats={formats}
                        />
                        {errors.description && <div className="error-message">{errors.description}</div>}
                    </div>
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Status</p>
                        <Radio.Group
                            className="add_coupon_radio"
                            name="status"
                            onChange={handleStatusChange}
                            value={formData.status}
                        >
                            <Radio value="true">Enabled</Radio>
                            <Radio value="false">Disabled</Radio>
                        </Radio.Group>
                        {errors.status && <div className="error-message">{errors.status}</div>}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditFaqs;
