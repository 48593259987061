import React, { useEffect, useState } from 'react';
import Instance from '../../AxiosConfig';
import { RxDotFilled } from "react-icons/rx";
import './Brands.css';
import defaultImg from "../../Assets/images/defailt-no-image.png";
import EditBrands from './EditBrand';
import { RiDeleteBinLine } from 'react-icons/ri';

import { useSelector, useDispatch } from 'react-redux';

import { Modal, Spin } from 'antd';

import { message } from 'antd';
import { addBrands, removeBrand } from '../../states/reducers/BrandSlice';
import { BRAND_BASE_URL } from '../../GlobalConstant';


const BrandProducts = ({ categoryName }) => {
  const dispatch = useDispatch()
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const brands = useSelector((state) => state.brand.BrandData);
  console.log("dfhjk", brands)

  const { confirm } = Modal;



  const handleOpenAddPostModal = async (brandId) => {
    // const brandId=brands.brandId
    console.log("Product ID:", brandId);

    try {
      const response = await Instance.get(`/api/v1/brand/brandId/${brandId}`);
      setSelectedProduct(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };

  const showDeleteConfirm = (brandId) => {
    confirm({
      title: 'Are you sure you want to delete this brand?',
      content: 'Once deleted, the brand cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(brandId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDelete = async (brandId) => {
    try {
      await Instance.delete(`/api/v1/brand/${brandId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      setProductData(productData.filter(product => product.id !== brandId));
      message.success('Brand deleted successfully!');
      dispatch(removeBrand(brandId))
    } catch (error) {
      console.error("Failed to delete brand:", error);
      const errorMessage = error.response?.data?.message || "An unexpected error occurred.";

      const formattedMessage = errorMessage.split('.').join('.<br/>');

      message.error(
        <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />,
        6
      );
    }

  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let url = '/api/v1/brand/';
      const response = await Instance.get(url);
      console.log("FETCHED BRANDS DATA", response.data)
      dispatch(addBrands(response.data))
      setLoading(false);
    } catch (error) {
      setError(error.message);
      console.log(error.response)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch]);

  const handleEditModalClose = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
    fetchData();
  };
  const filteredBrands = categoryName
    ? brands.filter((brand) => brand.categories === categoryName)
    : brands;

  if (loading) {
    return <div className="loading"><Spin size="large" /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className=" pt-4 container">
      <div className="row">
        {filteredBrands.map((product) => (
          <div className="col-12 col-md-6 col-lg-4 custom-col-2560
           mb-4" key={product.brandId}>
            <div className="product-brand-card">
              <div className="d-flex justify-content-between">
                <p>{product.brandId}</p>
                <RiDeleteBinLine
                  className="delete_icon"
                  style={{ fontSize: '28px' }}
                  onClick={() => showDeleteConfirm(product.brandId)}
                />

              </div>
              <div className="d-flex justify-content-center align-items-center product-image">
                <img
                  src={
                    product.imageUrl
                      ? `${BRAND_BASE_URL}${product.imageUrl}`
                      : defaultImg
                  }
                  alt={product.name}
                  className="product-brand-image"
                />
              </div>

              <div className="d-flex justify-content-between product-brand-details">
                <div>
                  <h6 className="product-brand-name">{product.brandName}</h6>
                </div>
                <div>
                  <button className="edit-brand-button" onClick={() => handleOpenAddPostModal(product.brandId)}>Edit</button>
                </div>
              </div>
              <div className="d-flex justify-content-between product-brand-info">
                <div className="text-center">
                  <p>Weight</p>
                  <span>{product.totalKg} KG</span>
                </div>
                <div className="text-center">
                  <p>Availability</p>
                  <span>
                    <RxDotFilled
                      style={{
                        color: product.availability === "Yes" ? "green" : "red",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    {product.availability}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <EditBrands
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedProduct={selectedProduct}
        fetchData={fetchData}
        onClose={handleEditModalClose}
      />
    </div>
  );
};

export default BrandProducts;
