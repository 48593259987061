import "./App.css";
import Router from "./Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import TokenExpiredPopup from "./Pages/TokenExpiryPopUp";
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";

function App() {
  const [isTokenExpiredPopupVisible, setTokenExpiredPopupVisible] = useState(false);

  const checkTokenExpiry = () => {
    const token = sessionStorage.getItem('adminAuthToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          setTokenExpiredPopupVisible(true);
          sessionStorage.removeItem('adminAuthToken');
        } else {
          console.log('Token is valid.');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      console.log('No Token found.');
    }
  };

  useEffect(() => {
    checkTokenExpiry();
    const intervalId = setInterval(() => {
      checkTokenExpiry();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClosePopup = () => {
    setTokenExpiredPopupVisible(false);
  };

  return (
    <div className="App">
      <Router />
      <TokenExpiredPopup
        visible={isTokenExpiredPopupVisible}
        onClose={handleClosePopup}
      />
    </div>
  );
}

export default App;
