import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    UserData: [], 
  },
  reducers: {   
    addUsers(state, action) {
      if (Array.isArray(action.payload)) {
        state.UserData = action.payload; 
      } else if (action.payload && action.payload.userId) {
        state.UserData = [action.payload]; 
      } else {
        console.error('addUsers payload is not valid:', action.payload);
      }
    },
    addUser(state, action) {
      state.UserData.push({
        ...action.payload,
      });
    },
    editUser(state, action) {
      const { userId, ...updatedData } = action.payload;
      if (Array.isArray(state.UserData)) {
        const index = state.UserData.findIndex((user) => user.userId === userId);
        if (index !== -1) {
          state.UserData[index] = { ...state.UserData[index], ...updatedData };
        }
      } else {
        console.error('UserData is not an array!', state.UserData);
      }
    }
  },
});

export const { addUsers,  editUser,addUser } = UserSlice.actions;
export default UserSlice.reducer;