import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "./header.css";
// import logo from "../../Assets/logos/sidebar-logo.svg";
import logo from "../../Assets/images/white-bg-logo.png";
import { FiGrid } from "react-icons/fi";
import { LuUsers, LuFileText } from "react-icons/lu";
import { CgTime } from "react-icons/cg";
import { useSelector } from "react-redux";
import { FaRegUser } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import {
  IoIosInformationCircleOutline,
  IoMdSettings,
  IoMdStarOutline,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosLogOut
} from "react-icons/io";
import { RiShieldUserLine, RiCoupon2Line } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";
import { MdAddCircleOutline, MdOutlineAccountBalance, MdOutlineStorefront } from "react-icons/md";
import { LiaCubeSolid } from "react-icons/lia";
import { TbCircleSquare } from "react-icons/tb";
import { BsCart3 } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";


// import Swal from 'sweetalert2'
// import logoutimg from "../../Assets/icons/logout.svg"

function Sidebar({ isCollapsed, toggleCollapse }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isLinkActive = () => {
    return ["/review", "/contact-us", "/faqs", "/feedback"].includes(location.pathname);
  };

  const toggleDropdown = () => {
    if (!isLinkActive()) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  // const toggleCollapse = () => {
  //   setIsCollapsed(!isCollapsed);
  // };
  return (
    <>
      <button
        className="toggle-btn d-block d-sm-block"
        onClick={toggleSidebar}
      >
        <IoMenu style={{ width: "24px", height: "24px" }} />
        <span className="visually-hidden">Toggle sidebar</span>
        {/* <span className="navbar-toggler-icon" /> */}
      </button>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""} ${isCollapsed ? "collapsed" : ""}`}>
        <aside className="sidebar-content">
          <div className="sidebar-header">
            {!isCollapsed && (
              <img src={logo} alt="logo" className="sidebar-logo" />
            )}

            <button className="collapse-btn d-none d-sm-block " onClick={toggleCollapse}>
              {isCollapsed ? <IoMenu /> : <IoMdClose />}
            </button>
            <button className="close-btn d-block d-sm-none" onClick={toggleSidebar}>
              <IoMdClose />
            </button>
          </div>
          <nav className={`sidebar-nav  ${isCollapsed ? "collapsed" : ""}`}>
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/dashboard"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <FiGrid className="sidebar-icon" /> {!isCollapsed && "Dashboard"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/manage-orders"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/manage-orders"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <BsCart3 className="sidebar-icon" /> {!isCollapsed && "Manage Orders"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/transaction"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/transaction"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <LuFileText className="sidebar-icon" /> {!isCollapsed && "Transaction"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/Users"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/Users"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <LuUsers className="sidebar-icon" /> {!isCollapsed && "Users"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/coupon"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/coupon"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <RiCoupon2Line className="sidebar-icon" /> {!isCollapsed && "Coupon Code"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/ProductCategories"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/ProductCategories"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <TbCircleSquare className="sidebar-icon" /> {!isCollapsed && "Product Categories"}

                  </span>
                </Link>
              </li>

              <li>
                <Link
                  to="/brands"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/brands"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <IoMdStarOutline className="sidebar-icon" /> {!isCollapsed && "Brands"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/add-product"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/add-product"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <MdAddCircleOutline className="sidebar-icon" /> {!isCollapsed && "Add Products"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/products-list"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/products-list"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <LiaCubeSolid className="sidebar-icon" />
                    {!isCollapsed && "Product List"}
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/account"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/account"
                      ? "active-nav-links"
                      : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <MdOutlineAccountBalance className="sidebar-icon" /> Account
                  </span>
                </Link>
              </li> */}

              <div className="line-dashed"></div>

              <li>
                <Link
                  to="/manage-admin"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/manage-admin"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <FaRegUserCircle className="sidebar-icon" /> {!isCollapsed && "Manage Admins"}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin-roles"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/admin-roles"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <RiShieldUserLine className="sidebar-icon" /> {!isCollapsed && "Admin Roles"}
                  </span>
                </Link>
              </li>

              <li>
                <Link
                  to="/store-information"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/store-information"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <MdOutlineStorefront className="sidebar-icon" /> {!isCollapsed && "Store Information"}
                  </span>
                </Link>
              </li>

              <li>
                <Link
                  to="/settings"
                  className={`nav-link py-2 rounded-xl mb-2 ${location.pathname === "/settings"
                    ? "active-nav-links"
                    : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <IoMdSettings className="sidebar-icon" /> {!isCollapsed && "Settings"}
                  </span>
                </Link>
              </li>

              <li>
                <Link
                  className={`nav-link py-2 rounded-xl ${location.pathname === "/" ? "active-nav-links" : "#A0AEC0"
                    }`}
                  onClick={toggleDropdown}
                >
                  <span className="">
                    <IoIosInformationCircleOutline className="sidebar-icon" />{" "}
                    {!isCollapsed && "Customer Support"} {dropdownOpen || isLinkActive() ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                </Link>
                {(dropdownOpen || isLinkActive()) && (
                  <div className="dropdown-menus">
                    <Link
                      to="/review"
                      className={`nav-link py-2 rounded-xl ${location.pathname === "/review" ? "active-nav-links" : "#A0AEC0"
                        }`}
                    >
                      <span className="me-3">
                        <FaRegUser className="sidebar-icon" /> {!isCollapsed && "Review"}
                      </span>
                    </Link>
                    <Link
                      to="/contact-us"
                      className={`nav-link py-2 rounded-xl ${location.pathname === "/contact-us" ? "active-nav-links" : "#A0AEC0"
                        }`}
                    >
                      <span className="me-3">
                        <IoMdCall className="sidebar-icon" /> {!isCollapsed && "Contact Us"}
                      </span>
                    </Link>
                    <Link
                      to="/faqs"
                      className={`nav-link py-2 rounded-xl ${location.pathname === "/faqs" ? "active-nav-links" : "#A0AEC0"
                        }`}
                    >
                      <span className="me-3">
                        <CgTime className="sidebar-icon" /> {!isCollapsed && "FAQ's"}
                      </span>
                    </Link>
                    <Link
                      to="/feedback"
                      className={`nav-link py-2 rounded-xl ${location.pathname === "/feedback" ? "active-nav-links" : "#A0AEC0"
                        }`}
                    >
                      <span className="me-3">
                        <VscFeedback className="sidebar-icon" /> {!isCollapsed && "Feedback"}
                      </span>
                    </Link>
                  </div>
                )}
              </li>
              <li>
                <Link
                  to="/logout"
                  className={`nav-link py-2 rounded-xl ${location.pathname === "/logout" ? "active-nav-links" : "#A0AEC0"
                    }`}
                >
                  <span className="me-3">
                    <IoIosLogOut className="sidebar-icon" /> {!isCollapsed && "Log Out"}
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
    </>
  );
}

export default Sidebar;
