import React, { useEffect, useState } from "react";
import "./ManageOrder.css";
import { useLocation, useNavigate } from "react-router-dom";
// import CouponList from "./CouponList";
import { FaPlus } from "react-icons/fa6";
import { Menu } from "antd";
import ManageOrderList from "./ManageOrderList";
import InProgressList from "./InProgressList";
import ShippedList from "./ShippedList";
import DeliveredList from "./DeliveredList";
import CancelledList from "./CancelledList";
import PendingList from "./PendingList";

const ManageOrder = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.state?.currentTab || "orderStatus");
  const items = [
    {
      label: "Order Status",
      key: "orderStatus",
    },
    {
      label: "Pending",
      key: "pending",
    },
    {
      label: "In Progress",
      key: "inProgress",
    },
    {
      label: "Shipped",
      key: "shipped",
    },
    {
      label: "Delivered",
      key: "delivered",
    },
    {
      label: "Cancelled",
      key: "cancelled",
    },
  ];

  // const [current, setCurrent] = useState(location.state?.currentTab || "orderStatus");

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  useEffect(() => {
    if (location.state?.currentTab) {
      console.log("CURRENT TAB:", location.state?.currentTab)
      setCurrent(location.state.currentTab);
    }
  }, [location.state]);

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start", marginBottom: "30px" }}>
          <span>Manage Orders</span>
        </div>
      </div>
      <Menu
        style={{ marginBottom: "30px" }}
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        className="manage-order-menu"
      />
      {current === "orderStatus" && <ManageOrderList />}
      {current === "pending" && <PendingList />}
      {current === "inProgress" && <InProgressList />}
      {current === "shipped" && <ShippedList />}
      {current === "delivered" && <DeliveredList />}
      {current === "cancelled" && <CancelledList />}

    </div>
  );
};

export default ManageOrder;
