// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
// import Instance from '../../AxiosConfig';
// import { Spin } from 'antd';

// Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const gradientShadowPlugin = {
//     id: 'gradientShadow',
//     beforeDatasetsDraw: (chart, args, options) => {
//         const { ctx, chartArea } = chart;
//         const { top, bottom, left, right } = chartArea;

//         ctx.save();

//         const dataset = chart.data.datasets[0];
//         const points = chart.getDatasetMeta(0).data || [];

//         if (points.length > 0) {
//             const firstPoint = points[0].getProps(['x', 'y']);
//             const lastPoint = points[points.length - 1].getProps(['x', 'y']);

//             const gradient = ctx.createLinearGradient(0, top, 0, bottom);
//             gradient.addColorStop(0, 'rgba(27, 58, 87, 0.5)');
//             gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

//             ctx.fillStyle = gradient;
//             ctx.beginPath();
//             ctx.moveTo(firstPoint.x, bottom); // Start from the bottom left corner

//             points.forEach(point => {
//                 ctx.lineTo(point.getProps(['x', 'y']).x, point.getProps(['x', 'y']).y);
//             });

//             ctx.lineTo(lastPoint.x, bottom); // Go to the bottom right corner
//             ctx.closePath();
//             ctx.fill();
//         }

//         ctx.restore();
//     }
// };

// const SalesDetailsChart = ({ selectedYear }) => {
//     // const [dataa, setData] = useState({
//     //     totalOrdersLast7Days: 0,
//     //     totalProductsLast7Days: 0,
//     //     totalSellingLast7Days: 0,
//     //     orders: [],
//     //     weeklyOrderCounts: {},
//     //     weeklyProductCounts: {},
//     //     weeklySellingCounts: {},
//     //     monthlySellingCounts: {},
//     // });
//     const [dataa, setData] = useState([]);
//     const [loading, setLoading] = useState(true);


//     useEffect(() => {
//         fetchOrdersData();
//     }, []);

//     const fetchOrdersData = async () => {
//         setLoading(true);
//         try {
//             const url = "/api/v1/admin/orders/dashboard";
//             const response = await Instance.get(url);
//             if (response.status === 200 || response.status === 201) {
//                 const ordersData = response.data;
//                 setData(ordersData);
//                 console.log("Fetched data:", ordersData);
//             }
//         } catch (error) {
//             console.error("Failed to fetch data:", error);
//         }
//         setLoading(false);
//     };

//     console.log("dataa:", dataa)

//     const labels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
//     const salesData = Object.values(dataa);

//     const data = {
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Sales Details',
//                 data: salesData,
//                 borderColor: '#445a76',
//                 backgroundColor: 'rgba(27, 58, 87, 0.2)', // This is just a fallback
//                 fill: true,
//                 tension: 0.4,
//                 pointBackgroundColor: '#445a76',
//                 pointBorderColor: '#445a76',
//                 pointHoverBackgroundColor: '#445a76',
//                 pointHoverBorderColor: '#445a76',
//                 borderWidth: 1
//             }
//         ]
//     };

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 display: false
//             },
//             tooltip: {
//                 callbacks: {
//                     label: function (context) {
//                         let label = 'Number of Sales';

//                         if (label) {
//                             label += ': ';
//                         }
//                         if (context.parsed.y !== null) {
//                             label += new Intl.NumberFormat('en-US').format(context.parsed.y);
//                         }
//                         return label;
//                     }
//                 }
//             },
//             gradientShadowPlugin: {
//                 beforeDatasetsDraw: (chart, args, options) => gradientShadowPlugin.beforeDatasetsDraw(chart, args, options)
//             }
//         },
//         scales: {
//             x: {
//                 display: true,
//                 grid: {
//                     display: false
//                 }
//             },
//             y: {
//                 beginAtZero: false,
//                 ticks: {
//                     callback: function (value) {
//                         return value;
//                     },
//                     stepSize: 20,
//                     padding: 10
//                 },
//                 grid: {
//                     color: '#e0e0e0',
//                     borderDash: [2, 2],
//                     drawBorder: false,
//                 },
//                 border: {
//                     display: false
//                 }
//             }
//         }
//     };

//     return (
//         <div className="chart-container" style={{ position: 'relative', height: '300px', width: '100%', paddingBottom:"10px" }}>
//             {loading ? (
//                 <Spin />
//             ) : (
//                 <Line data={data} options={options} />
//             )}
//         </div>
//     );
// };

// export default SalesDetailsChart;



import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Instance from '../../AxiosConfig';
import { Spin } from 'antd';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const gradientShadowPlugin = {
    id: 'gradientShadow',
    beforeDatasetsDraw: (chart, args, options) => {
        const { ctx, chartArea } = chart;
        const { top, bottom, left, right } = chartArea;

        ctx.save();

        const dataset = chart.data.datasets[0];
        const points = chart.getDatasetMeta(0).data || [];

        if (points.length > 0) {
            const firstPoint = points[0].getProps(['x', 'y']);
            const lastPoint = points[points.length - 1].getProps(['x', 'y']);

            const gradient = ctx.createLinearGradient(0, top, 0, bottom);
            gradient.addColorStop(0, 'rgba(27, 58, 87, 0.5)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

            ctx.fillStyle = gradient;
            ctx.beginPath();
            ctx.moveTo(firstPoint.x, bottom); // Start from the bottom left corner

            points.forEach(point => {
                ctx.lineTo(point.getProps(['x', 'y']).x, point.getProps(['x', 'y']).y);
            });

            ctx.lineTo(lastPoint.x, bottom); // Go to the bottom right corner
            ctx.closePath();
            ctx.fill();
        }

        ctx.restore();
    }
};

const SalesDetailsChart = ({ selectedYear }) => {
    const [dataa, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchOrdersData(selectedYear);
    }, [selectedYear]);

    const fetchOrdersData = async (year) => {
        setLoading(true);
        try {
            const url = `/api/v1/admin/orders/monthly-selling-counts?year=${year}`;
            const response = await Instance.get(url);
            if (response.status === 200 || response.status === 201) {
                const ordersData = response.data;
                setData(ordersData);
                console.log("Fetched data 123:", ordersData);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
        setLoading(false);
    };

    const labels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const salesData = Object.values(dataa);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Sales Details',
                data: salesData,
                borderColor: '#445a76',
                backgroundColor: 'rgba(27, 58, 87, 0.2)', // This is just a fallback
                fill: true,
                tension: 0.4,
                pointBackgroundColor: '#445a76',
                pointBorderColor: '#445a76',
                pointHoverBackgroundColor: '#445a76',
                pointHoverBorderColor: '#445a76',
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = 'Number of Sales';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
            gradientShadowPlugin: {
                beforeDatasetsDraw: (chart, args, options) => gradientShadowPlugin.beforeDatasetsDraw(chart, args, options)
            }
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: false,
                ticks: {
                    callback: function (value) {
                        return value;
                    },
                    stepSize: 20,
                    padding: 10
                },
                grid: {
                    color: '#e0e0e0',
                    borderDash: [2, 2],
                    drawBorder: false,
                },
                border: {
                    display: false
                }
            }
        }
    };

    return (
        <div className="chart-container" style={{ position: 'relative', height: '300px', width: '100%', paddingBottom: "10px" }}>
            {loading ? (
                <Spin />
            ) : (
                <Line data={data} options={options} />
            )}
        </div>
    );
};

export default SalesDetailsChart;

