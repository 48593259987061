import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker } from "antd";
import moment from "moment";
import { ProductList } from "./ProductList";

const Products = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.format("YYYY-MM-DD") : null);
  };

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start" }}>
          <span>Products List</span>
        </div>
      </div>
      <div className="filter-row">
        <div className="select-options media-productlist">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input-table me-2"
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="search-table-container">
          <DatePicker
            placeholder="Filter by Date"
            format="YYYY-MM-DD"
            value={selectedDate ? moment(selectedDate) : null}
            onChange={handleDateChange}
            onFocus={() => setSelectedDate(null)}
          />
        </div>
      </div>
      <ProductList searchText={searchText} selectedDate={selectedDate} />
    </div>
  );
};

export default Products;