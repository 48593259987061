import React, { useState } from "react";
import { Modal, Input, Form, message, DatePicker, Spin } from "antd";
import './ManageAdmins.css';
import Instance from "../../AxiosConfig";
import { useDispatch } from "react-redux";
import { addRole } from "../../states/reducers/RoleSlice";
const NewRole = ({ isModalVisible2, setIsModalVisible2 }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const dispatch=useDispatch();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            const role = {
                roleName: values.role,
            };

            const response = await Instance.post('/api/v1/roleAccess/create-role', role, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log("ROLES: ", response.data);
                setIsModalVisible2(false);
                message.success('Role created successfully!');
                dispatch(addRole(response.data));
                form.resetFields();
                setLoading(false)
            }
        } catch (error) {
            if (error.response) {
                message.error(error.response.data.message);
            } else {
                message.error('Please complete the form correctly.');
                console.log('Validate Failed:', error);
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal
            title="Add Role"
            visible={isModalVisible2}
            // onOk={handleSubmit}
            onCancel={() => setIsModalVisible2(false)}
            footer={[
                <div className="d-flex justify-content-end gap-2" key="footer">
                    {/* <button
                        style={{
                            backgroundColor: "#f6f6f6",
                            border: "none",
                            borderRadius: "15px",
                            padding: "10px 20px",
                        }}
                        onClick={() => setIsModalVisible2(false)}
                    >
                        Discard
                    </button> */}

                    <button
                        style={{
                            backgroundColor: "#1b3a57",
                            border: "none",
                            borderRadius: "15px",
                            color: "#fff",
                            padding: "10px 20px",
                        }}
                        onClick={handleSubmit}
                    >
                        {loading ? (
                            <Spin size="small" />
                        ) : (
                            "Add Role"
                        )}
                    </button>




                </div>,
            ]}
        >
            <div className="add-brand-section pt-2">
                <Form
                    form={form}
                    layout="vertical"
                    name="new_user_form"
                >
                    <div className="row">
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Role Name</span>}
                                name="role"
                                rules={[{ required: true, message: 'Please enter a Role!' }]}
                            >
                                <Input
                                    placeholder="Enter a new role"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default NewRole;
