import React, { useState } from "react";
import { Modal, Button, message, Spin } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Instance from "../../AxiosConfig";

const AddCategories = ({ isModalVisible, setIsModalVisible }) => {
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [loading, setLoading] = useState(false)

  const modules = {
    toolbar: [
      [{ font: [] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
  ];

  const stripHtml = (html) => {
    let div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const validateFields = () => {
    let isValid = true;

    if (!categoryTitle.trim()) {
      setTitleError("Category Title is required");
      isValid = false;
    } else {
      setTitleError("");
    }

    const descriptionText = stripHtml(categoryDescription).trim();
    if (descriptionText.length < 50) {
      setDescriptionError("Description must be at least 100 characters long");
      isValid = false;
    } else if (descriptionText.length > 500) {
      setDescriptionError("Description cannot exceed 1300 characters");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    return isValid;
  };

  const handleSave = async () => {
    setLoading(true);
    if (!validateFields()) {
      return;
    }

    const categoryData = {
      categoryTitle,
      categoryDescription: stripHtml(categoryDescription),
    };

    try {
      const token = sessionStorage.getItem("adminAuthToken");

      const response = await Instance.post(
        "/api/v1/categories/create",
        categoryData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success("Category added successfully!");
        setIsModalVisible(false);
        setCategoryTitle("");
        setCategoryDescription("");
        setLoading(false)
      } else {
        console.log("Failed to add category", response.data);
        message.error("Failed to add category");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred while adding the category";

      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Modal
        title="Add Categories"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            className="discard_button"
            key="cancel"
            onClick={() => setIsModalVisible(false)}
          >
            Discard
          </Button>,

          <Button
            className="save_changes_button"
            key="submit"
            type="primary"
            onClick={handleSave}
          >
            {loading ? (
              <Spin size="small" />
            ) : (
              "Save & Publish"
            )

            }

          </Button>,
        ]}
      >
        <div>
          <div className="col-lg-12 mt-5 " style={{ textAlign: "left" }}>
            <label htmlFor="inputEmail4" className="form-label">
              Category Title
            </label>
            <input
              type="text"
              className="form-control form_control"
              placeholder="Add category"
              id="inputEmail4"
              value={categoryTitle}
              onChange={(e) => setCategoryTitle(e.target.value)}
            />
            {titleError && <p style={{ color: "red" }}>{titleError}</p>}
          </div>
          <div className="row mt-4">
            <div className="editor-container">
              <p className="product_description_heading">
                Product Long Description
              </p>
              <ReactQuill
                value={categoryDescription}
                onChange={setCategoryDescription}
                placeholder="Your text goes here"
                modules={modules}
                formats={formats}
              />
              {descriptionError && (
                <p style={{ color: "red", marginTop: "5px" }}>
                  {descriptionError}
                </p>
              )}
            </div>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCategories;
