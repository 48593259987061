import React, { useState, useEffect } from "react";
import profile from "../../Assets/images/singleuser.png";
import ContactReply from "./ContactReply";
import Instance from "../../AxiosConfig";
import { message, Spin } from "antd";
import { HiOutlineInbox } from "react-icons/hi2";

export const Contactlist = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentContactUs, setCurrentContactUs] = useState(null);
  const [currentRating, setCurrentRating] = useState(0);
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleOpenAddPostModal = (contactUsId) => {
    // console.log("current Cpntact Reply:", contactUsId);
    setCurrentContactUs(contactUsId);
    setIsModalVisible(true);
  };

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/contact-us/all`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Contact data fetched successfully:", response.data);
        const formattedContacts = response.data.map((contact) => {
          const date = new Date(contact.createdDate);
          const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }).format(date);
          return {
            ...contact,
            formattedDate: `Posted on ${formattedDate}`,
          };
        });
        setContactData(formattedContacts);
      }
    } catch (error) {
      console.log("error:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {contactData.length === 0 ? (
            <div className="no-inquiries">
              <HiOutlineInbox style={{ color: "#ffded1", fontSize: "70px" }} />

              <p>No New Inquiries Available</p>
            </div>
          ) : (
            contactData.map((contact, index) => (
              <div key={index} className="review-card-div mb-4">
                <div className="d-flex justify-content-between reviews-how-to-apply-div">
                  <div className="d-flex gap-4">
                    <h6 className="reviews-how-to-apply">{contact.subject}</h6>
                  </div>
                  <p className="reviews-posted-at">{contact.formattedDate}</p>
                </div>
                <p><strong>Service:</strong> {contact.services}</p>
                <p>{contact.message}</p>
                <div className="straight-line my-2"></div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="profile-email-icon">
                      <img src={profile} alt="profile" />
                    </div>
                    <div>
                      <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                        {contact.name}
                      </p>
                      {/* <p>{contact.email}</p>
                <p>{contact.phoneNumber}</p> */}
                    </div>
                  </div>
                  <button
                    className="review-reply-button"
                    onClick={() => handleOpenAddPostModal(contact.contactUsId)}
                  >
                    Reply
                  </button>
                </div>
              </div>
            ))
          )}
          {currentContactUs && (
            <ContactReply
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              contactUsId={currentContactUs}
            />
          )}
        </>
      )}

    </div>
  );
};
