import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Pagination, Select, DatePicker, Modal, message, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import Instance from "../../AxiosConfig";
import { IoIosArrowDown } from "react-icons/io";
import EditCoupon from "./EditCoupon";
import moment from 'moment';

const { Option } = Select;
const { confirm } = Modal;


const CouponList = () => {
  const [searchText, setSearchText] = useState("");
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [createdDate, setCreatedDate] = useState(null);
  const [sorterState, setSorterState] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCouponData();
  }, []);

  useEffect(() => {
    applyFiltersAndPagination();
  }, [allData, createdDate, searchText, pagination.current, pagination.pageSize]);

  const fetchCouponData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/coupons/all?pageNumber=0&pageSize=10000&sortBy=couponId&sortDir=desc";
      const response = await Instance.get(url);
      if (response.status === 200) {
        const { content: coupons } = response.data;
        setAllData(coupons);
        setPagination(prev => ({
          ...prev,
          total: coupons.length,
        }));
        console.log("Fetched Coupons:", coupons);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  const applyFiltersAndPagination = (sorter) => {
    let filtered = [...allData];

    if (createdDate) {
      filtered = filtered.filter((coupon) =>
        moment(coupon.createdDate).isSame(createdDate, "day")
      );
    }

    if (searchText) {
      const trimmedSearchText = searchText.trim();
      const searchTerms = trimmedSearchText.toLowerCase().split(' ');
      filtered = filtered.filter((coupon) => {
        return searchTerms.every(term =>
          coupon.couponCode.toLowerCase().includes(term) ||
          coupon.couponType.toLowerCase().includes(term)
        );
      });
    }

    const currentSorter = sorter || sorterState;
    if (currentSorter?.field && currentSorter?.order) {
      filtered.sort((a, b) => {
        const valueA = a[currentSorter.field];
        const valueB = b[currentSorter.field];

        if (currentSorter.field === "startDate" || currentSorter.field === "endDate") {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);

          if (!isNaN(dateA) && !isNaN(dateB)) {
            return currentSorter.order === "ascend" ? dateA - dateB : dateB - dateA;
          }
          return 0;
        }

        return currentSorter.order === "ascend"
          ? valueA?.localeCompare(valueB)
          : valueB?.localeCompare(valueA);
      });
    }

    setFilteredData(filtered);
    paginateData(filtered, currentSorter);
  };

  const paginateData = (data, sorter) => {
    const currentSorter = sorter || sorterState;

    if (currentSorter?.field && currentSorter?.order) {
      data.sort((a, b) => {
        const valueA = a[currentSorter.field];
        const valueB = b[currentSorter.field];

        if (currentSorter.field === "startDate" || currentSorter.field === "endDate") {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);

          if (!isNaN(dateA) && !isNaN(dateB)) {
            return currentSorter.order === "ascend" ? dateA - dateB : dateB - dateA;
          }
          return 0;
        }

        return currentSorter.order === "ascend"
          ? valueA?.localeCompare(valueB)
          : valueB?.localeCompare(valueA);
      });
    }

    const start = (pagination.current - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;

    setDisplayedData(data.slice(start, end));
    setPagination((prev) => ({
      ...prev,
      total: data.length,
    }));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSorterState(sorter);
    applyFiltersAndPagination(sorter);
  };
  useEffect(() => {
    // Reset pagination to first page when searchText changes
    setPagination((prev) => ({
      ...prev,
      current: 1,
    }));

    applyFiltersAndPagination(sorterState);
  }, [createdDate, searchText]);


  useEffect(() => {
    applyFiltersAndPagination(sorterState);
  }, [createdDate, searchText]);

  useEffect(() => {
    paginateData(filteredData, sorterState);
  }, [pagination.current, pagination.pageSize]);

  useEffect((sorter) => {
    paginateData(filteredData, sorter);
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    applyFiltersAndPagination();
  }, [createdDate, searchText]);







  const handleDelete = async (couponCode) => {
    try {
      const url = `/api/v1/coupons/${couponCode}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        fetchCouponData();
        message.success(response.data.message);
      }
    } catch (error) {
      console.log({ error });
      message.error("Failed to delete coupon");
    }
  };

  const handleOpenAddPostModal = async (couponCode) => {
    console.log("Product ID:", couponCode);
    try {
      const response = await Instance.get(`/api/v1/coupons/code/${couponCode}`);
      setSelectedProduct(response.data);
      console.log(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };

  const handleEditModalClose = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
    fetchCouponData();
  };

  const columns = [
    {
      title: "PRODUCT ID",
      dataIndex: "productId",
      render: (text) => (
        <div style={{ marginLeft: "20px" }}>
          {text}
        </div>
      ),
    },
    {
      title: "CODE",
      dataIndex: "couponCode",
      render: (text) => `${text}`,
    },
    {
      title: "TYPE",
      dataIndex: "couponType",
      render: (text) => {
        const formattedText = text
          .replace(/_/g, " ")
        return formattedText;
      },
    },
    {
      title: "DISCOUNT",
      dataIndex: "value",
      render: (value, record) =>
        (record.couponType === "FREE_SHIPPING") || (value === "") || (value === null) ? "FREE" : record.couponType === "percentage" ? `${value}%` : `${value}`,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (status) => {
        let color, style, displayText;
        switch (status) {
          case "enabled":
            color = "green";
            displayText = "Enabled";
            style = {
              fontSize: "14px",
              fontWeight: "600",
              border: "none",
              color: "#28C76F",
              padding: "1px 5px",
              backgroundColor: "#DDF6E8",
            };
            break;
          case "disabled":
            color = "orange";
            displayText = "Disabled";
            style = {
              fontSize: "14px",
              fontWeight: "600",
              border: "none",
              color: "#F57C00",
              padding: "1px 5px",
              backgroundColor: "#FFE7D9",
            };
            break;
          case "expired":
            color = "red";
            displayText = "Expired";
            style = {
              fontSize: "14px",
              fontWeight: "600",
              border: "none",
              color: "#FF4D4F",
              padding: "1px 5px",
              backgroundColor: "#FFE2E2",
            };
            break;
          default:
            color = "gray";
            displayText = "Unknown";
            style = {
              fontSize: "14px",
              fontWeight: "600",
              border: "none",
              color: "#BFBFBF",
              padding: "1px 5px",
              backgroundColor: "#F0F0F0",
            };
        }
    
        return (
          <Tag color={color} style={style}>
            {displayText}
          </Tag>
        );
      },
    },
    
    {
      title: "START DATE",
      dataIndex: "startDate",
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "END DATE",
      dataIndex: "endDate",
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
      render: (text) =>
        new Date(text).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="delete_edit_icons">
          <Popconfirm
            title="Are you sure you want to delete this coupon?"
            onConfirm={() => handleDelete(record.couponCode)}
            onCancel={() => message.info('Coupon deletion aborted')}
            okText="Yes"
            cancelText="No"
          >
            <RiDeleteBinLine className="delete_icon" />
          </Popconfirm>
          <CiEdit
            className="edit_icon"
            onClick={() => handleOpenAddPostModal(record.couponCode)}
          />
        </div>
      ),
    },
  ];





  const handlePageSizeChange = (value) => {
    setPagination(prev => ({
      ...prev,
      pageSize: value,
      current: 1,
    }));
  };

  const handleDateChange = (date) => {
    setCreatedDate(date ? date.format('YYYY-MM-DD') : null);
    setPagination(prev => ({
      ...prev,
      current: 1, // Reset to first page when date is changed
    }));
  };

  return (
    <div className="user-list-section">
      <div className="filter-row coupon-text">
        <div className="select-options media-coupon">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input-table me-2"
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="search-table-container">
          <DatePicker
            placeholder="Filter by Date"
            format="YYYY-MM-DD"
            value={createdDate ? moment(createdDate) : null}
            onChange={handleDateChange}
            onFocus={() => setCreatedDate(null)}
            disabledDate={(current) => current && current > moment().endOf('day')}
            onKeyDown={(e) => {
              const allowedKeys = /[0-9/-]/;
              if (
                !allowedKeys.test(e.key) &&
                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={displayedData}
          pagination={false} // Disable built-in pagination
          loading={loading}
          onChange={handleTableChange}
        />
        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={(page, pageSize) => {
              setPagination(prev => ({
                ...prev,
                current: page,
                pageSize: pageSize,
              }));
            }}
          />
        </div>
      </div>
      <EditCoupon
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedProduct={selectedProduct}
        fetchCouponData={fetchCouponData}
        onClose={handleEditModalClose}
      />
    </div>
  );
};

export default CouponList;