import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Radio, Checkbox, DatePicker, message } from "antd";
import Instance from "../../AxiosConfig";
import dayjs from 'dayjs';

const AddCoupon = ({ isModalVisible, setIsModalVisible, productId }) => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [error, setError] = useState({});
    const [couponCodeError, setCouponCodeError] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [formData, setFormData] = useState({
        couponCode: '',
        status: null,
        couponType: null,
        value: '',
        registeredOnly: false,
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        if (selectedStartDate) {
            const startDateTime = dayjs(selectedStartDate)
                .set('hour', dayjs().hour())
                .set('minute', dayjs().minute())
                .set('second', dayjs().second())
                .format('YYYY-MM-DD HH:mm:ss');
            setFormData(prevFormData => ({
                ...prevFormData,
                startDate: startDateTime
            }));
        }
    }, [selectedStartDate]);

    useEffect(() => {
        if (selectedEndDate) {
            const endDateTime = dayjs(selectedEndDate)
                .set('hour', dayjs().hour())
                .set('minute', dayjs().minute())
                .set('second', dayjs().second())
                .format('YYYY-MM-DD HH:mm:ss');
            setFormData(prevFormData => ({
                ...prevFormData,
                endDate: endDateTime
            }));
        }
    }, [selectedEndDate]);

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value);

        const couponTypes = {
            1: "PERCENTAGE",
            2: "DISCOUNT_AMOUNT",
            3: "FREE_SHIPPING"
        };

        setFormData({
            ...formData,
            couponType: couponTypes[value] || null,
            value: ''
        });
    };

    const resetForm = () => {
        setFormData({
            couponCode: '',
            status: '',
            couponType: null,
            value: '',
            registeredOnly: '',
            startDate: '',
            endDate: '',
        })
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };


    const handleStatusChange = (e) => {
        const status = e.target.value === 1 ? "enabled" : "disabled";
        setFormData({
            ...formData,
            status
        });
    };

    const handleFieldChange = (e, field) => {
        let value = e.target.value;

        if (field === 'couponCode') {
            value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
            setCouponCode(value);
        }

        if (field === 'registeredOnly') {
            value = e.target.checked;
        }

        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleValueChange = (e) => {
        let value = e.target.value;

        if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
            const numericValue = parseInt(value, 10);

            if (selectedValue === 1 && (numericValue < 1 || numericValue > 100)) {
                message.error('Percentage must be between 1 and 100')
            } else {
                setFormData({
                    ...formData,
                    value
                });
            }
        }
    };

    const validateForm = () => {
        const newError = {};

        if (!formData.couponCode) {
            newError.couponCode = 'Coupon Code is required';
        }
        if (!formData.status) {
            newError.status = 'Status must be chosen';
        }
        if (!formData.couponType) {
            newError.couponType = 'Coupon Type must be chosen';
        }
        if (!formData.value && (selectedValue === 1 || selectedValue === 2)) {
            newError.value = 'Value is required';
        }

        if (!selectedStartDate) {
            newError.startDate = 'Start Date is required';
        }
        if (!selectedEndDate) {
            newError.endDate = 'End Date is required';
        }
        // if (!formData.registeredOnly) {
        //     newError.registeredOnly = 'Checkbox must be selected';
        // }

        setError(newError);
        return Object.keys(newError).length === 0;
    };

    const handleSaveChanges = async () => {
        if (!validateForm()) {
            return;
        }

        const payload = {
            couponCode: formData.couponCode,
            status: formData.status,
            couponType: formData.couponType,
            value: formData.value,
            registeredOnly: formData.registeredOnly,
            startDate: formData.startDate,
            endDate: formData.endDate
        };

        try {
            const response = await Instance.post(`/api/v1/coupons/create/${productId}`, payload, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200 || response.status === 201) {
                message.success('Coupon created successfully!');
                resetForm();
                setError({});
                setIsModalVisible(false);
            }
        } catch (error) {
            setError({ apiError: error.response?.data?.message || 'An error occurred' });
            console.error(error);
        }
    };




    return (
        <Modal
            title="Add Coupon"
            visible={isModalVisible}
            onOk={handleSaveChanges}
            onCancel={() => {
                setIsModalVisible(false);
                setCouponCodeError("");
                setError({});
                resetForm();
            }}
            footer={[
                <Button className="discard_button" key="cancel" onClick={() => {
                    setIsModalVisible(false);
                    setCouponCodeError("");
                    setError({});
                    resetForm();
                }}>
                    Discard
                </Button>,
                <Button className="save_changes_button" key="submit" type="primary" onClick={handleSaveChanges}>
                    Save Changes
                </Button>,
            ]}
        >
            <div className="add-user-section">
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Coupon Information</p>
                        <Input
                            placeholder="Coupon Code"
                            className='form-control custom-placeholder'
                            value={formData.couponCode}
                            onChange={(e) => {
                                setCouponCode(e.target.value);
                                handleFieldChange(e, 'couponCode');
                            }}
                        />
                        {error.couponCode && <p style={{ color: 'red' }}>{error.couponCode}</p>}
                        {couponCodeError && <p style={{ color: 'red' }}>{couponCodeError}</p>}
                    </div>
                    {error.apiError && <p style={{ color: 'red' }}>{error.apiError}</p>}
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Status</p>
                        <Radio.Group
                            className="add_coupon_radio"
                            name="status"
                            value={formData.status === "enabled" ? 1 : formData.status === "disabled" ? 2 : null}
                            onChange={handleStatusChange}
                        >
                            <Radio value={1}>Enabled</Radio>
                            <Radio value={2}>Disabled</Radio>
                        </Radio.Group>
                        {error.status && <p style={{ color: 'red' }}>{error.status}</p>}
                    </div>
                    <div className="col-lg-12 mb-3">
                        <p className="add_couponpage_hedings">Coupon Type</p>
                        <Radio.Group
                            className="add_coupon_radio"
                            name="couponType"
                            value={selectedValue}
                            onChange={handleRadioChange}
                            style={{ display: 'flex', gap: '10px' }}
                        >
                            <Radio value={1}>Percentage</Radio>
                            <Radio value={2}>Discount Amount</Radio>
                            <Radio value={3}>Free Shipping</Radio>
                        </Radio.Group>
                        {error.couponType && <p style={{ color: 'red' }}>{error.couponType}</p>}
                        {(selectedValue === 1 || selectedValue === 2) && (
                            <div className="col-lg-6 mb-4" style={{ marginTop: '10px' }}>
                                <Input
                                    placeholder="Enter Value"
                                    className='form-control'
                                    value={formData.value}
                                    onChange={handleValueChange}
                                    min={0}
                                    style={{ width: selectedValue === 1 ? '53%' : '60%' }}
                                />
                                {error.value && <p style={{ color: 'red' }}>{error.value}</p>}
                            </div>
                        )}
                    </div>
                    <div className="col-lg-12 mb-3">
                        <Checkbox
                            className="add_coupon_checkbox"
                            checked={formData.registeredOnly}
                            onChange={(e) => handleFieldChange(e, 'registeredOnly')}
                        >
                            Only for Registered Customers
                        </Checkbox>
                        {error.registeredOnly && <p style={{ color: 'red' }}>{error.registeredOnly}</p>}
                    </div>
                    <p className="add_couponpage_hedings">Schedule</p>
                    <div className="col-lg-6 mb-3">
                        <DatePicker
                            className="custom-date-picker"
                            onChange={(date) => setSelectedStartDate(date)}
                            placeholder="Start date"
                            value={selectedStartDate}
                        />
                        {error.startDate && <p style={{ color: 'red' }}>{error.startDate}</p>}
                    </div>
                    <div className="col-lg-6 mb-3">
                        <DatePicker
                            className="custom-date-picker"
                            onChange={(date) => setSelectedEndDate(date)}
                            placeholder="End date"
                            value={selectedEndDate}
                        />
                        {error.endDate && <p style={{ color: 'red' }}>{error.endDate}</p>}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddCoupon;
