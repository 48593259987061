import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(sessionStorage.getItem('user')) || null,
  jwtToken: sessionStorage.getItem('adminAuthToken') || null,
  roleAccess: JSON.parse(sessionStorage.getItem('roleAccess')) || [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.jwtToken = action.payload.jwtToken;
      state.roleAccess = action.payload.roleAccess;

      sessionStorage.setItem('user', JSON.stringify(action.payload.user));
      sessionStorage.setItem('adminAuthToken', action.payload.jwtToken);
      sessionStorage.setItem('roleAccess', JSON.stringify(action.payload.roleAccess));
    },
    logout: (state) => {
      state.user = null;
      state.jwtToken = null;
      state.roleAccess = [];

      sessionStorage.removeItem('user');
      sessionStorage.removeItem('adminAuthToken');
      sessionStorage.removeItem('roleAccess');
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
